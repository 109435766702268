<template>
  <div class="pbf">
     
     <BrandTabProducts
      :inurl="'/brands-seed/' + props.id + '/strains'"
      :strain-id="props.id"
      :category="'strain'"
      :intags="tagsProducts"
      />

  </div>
</template>

<script setup lang="ts">
 
import { TagsBrandProducts } from '@/types/other'

interface Props {
  id: number
}

const props = defineProps<Props>()

const tagsProducts = ref(structuredClone(TagsBrandProducts))


</script>


<style scoped>
 

</style>
